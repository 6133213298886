<template>
  <b-card-code>
    <b-card-text>
      <span class="faq-title">{{$t("Common Questions")}}</span>
    </b-card-text>

    <app-collapse accordion>
      <app-collapse-item class="question-title" :title="$t('What is a Premium Plan?')">
        <span>
           {{$t(`Dialog is a powerful management tool for your kitchen's running equipment, it can help you monitor the performance of the equipment which will reduce your maintenance costs.
            In addition, the intelligent software that indicates the status of the equipment provides valuable information to increase your productivity and control preventive maintenance.`)}}
        </span>
      </app-collapse-item>

      <app-collapse-item class="question-title" :title="$t('How do payments work and how much does it cost?')">
        <span>
           {{$t('You can choose to subscribe to Dialog annually or pay monthly. Use the price calculator tool to estimate the amount payable depending on the number of devices you want to synchronize. You can cancel your subscription at any time or edit the number of devices associated with your plan. If you choose to unsubscribe, you will continue to have access to the Dialog until the paid period ends.')}}
        </span>
      </app-collapse-item>

      <app-collapse-item class="question-title" :title="$t('What is the difference between users?')">
        <span>
        {{ $t('The user with an administrator role has full control over the synchronized devices and the platform, including the power to admit or reject new users. Common user can edit and view data. User with technician role can only view data. There is no limit to the number of registered users') }}
        </span>
      </app-collapse-item>
    </app-collapse>

    <template #code>
      {{ codeCollapseDefault }}
    </template>
  </b-card-code>
</template>

<script>
import { BCardText } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { codeCollapseDefault } from './code'

export default {
  components: {
    BCardCode,
    AppCollapse,
    AppCollapseItem,
    BCardText,
  },
  data() { return { codeCollapseDefault } },
}
</script>